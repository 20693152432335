import React from 'react'
import styled from 'styled-components'

// import font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faCheckCircle)

const ShipmentTrackerIndicatorPickedUp = styled.div`
    display: flex;
    flex-direction: row;

    > div{
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      height: 40px;

      &:first-child span{
        border-radius: 2px 0px 0px 2px;
        padding-left: 10px;
      }

      &:last-child span{
        border-radius: 0px 2px 2px 0px;
        padding-right: 10px;
      }

      &:not(:first-child){
        span{
          border-left-width: 0px;

          &:before{
            content: '';
            position: relative;
            display: inline-block;
            width: 0px;
            height: 0px;
            margin: -10px 10px -10px 1px;
            border-top: 21px solid transparent;
            border-bottom: 21px solid transparent;
            border-left: 10px solid #93afbf;
          }
        }

        &:before{
          content: '';
          position: relative;
          display: inline-block;
          width: 0px;
          height: 0px;
          margin: -11px -10px -11px 0;
          border-top: 22px solid transparent;
          border-bottom: 22px solid transparent;
          border-left: 10px solid white;
          z-index: 2;
        }
      }

      &:not(:last-child){
        padding-right: 1px;
        margin-right: 10px;

        span{
          border-right-width: 0px;
            &:after{
            content: '';
            position: relative;
            display: inline-block;
            width: 0px;
            height: 0px;
            margin: -10px -10px -10px auto;
            border-top: 21px solid transparent;
            border-bottom: 21px solid transparent;
            border-left: 10px solid #3daf2c;
            z-index: 1;          
          }
        }

        &:after{
          content: '';
          position: relative;
          display: inline-block;
          width: 0px;
          height: 0px;
          margin: -9px -10px -9px 0;
          border-top: 19px solid transparent;
          border-bottom: 19px solid transparent;
          border-left: 9px solid #ebffec;
          z-index: 1;          
        }
      }

      span{
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        height: 40px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        background-color: #ebffec;
        border: solid 1px #3daf2c;
        color: #3daf2c;
        z-index: 1;

      }

      &.is-current{
        span{
          color: white;
          background-color: #3daf2c;
        }

        &:after{
          border-left-color: #3daf2c;
        }
      }

      &.is-current ~ div{
        span{
          background-color: #e7edf0;
          border-color: #93afbf;
          color: #93afbf;

          &:after{
            border-left-color: #93afbf;
          }
        }

        &:after{
          border-left-color: #e7edf0;
        }
      }
    }
`;


const PageComponentShipmentTrackerIndicator = () => {
  return (
    <ShipmentTrackerIndicatorPickedUp>
        <div class="es-cg-ordered">
          <span>Ordered</span>
        </div>
        <div class="es-cg-booked">
          <span>Booked</span>
        </div>
        <div class="es-cg-checked-in">
          <span>Checked In</span>
        </div>
        <div class="es-cg-picked-up is-current">
            <span>Picked Up</span>
        </div>
        <div class="es-cg-delivered">
            <span>Delivered</span>
        </div>

    </ShipmentTrackerIndicatorPickedUp>
  )
}

export default PageComponentShipmentTrackerIndicator;