import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import NavPrimary from '../../../components/layouts/NavPrimary.js'
import SecondaryNav from '../../../components/layouts/NavSecondary.js'
import Footer from '../../../components/layouts/Footer.js'
import GPickedUpContentGrid from '../../../components/ContentGrids/Shipments/ShipmentDetails/ShipmentPickedUp.js'

const PGShipmentDetailsPickedUp = styled.div`
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  grid-template-rows: minmax(0.1fr, 5fr)0.1fr 0.3fr 0.2fr 5fr 0.2fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "nav_secondary"
    "nav_primary"
    "page_title"
    "g_content"
    "g_footer";

  .NavPrimary {
    grid-area: nav_primary; // 🗺
    align-self: start;
  }
  .SecondaryNav {
    grid-area: nav_secondary; // 🗺
    align-self: start;
  }
  .PageTitle {
    grid-area: page_title; // 🗺
    align-self: start;
  }
  .GBookedContentGrid {
    grid-area: g_content; // 🗺
  }
  .Footer {
    grid-area: g_footer; // 🗺
    align-self: end;
  }
`;

const GComponentPageTitle = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;  
`;
const PageTitle = styled.div`
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    margin: 0 auto;
    max-width: 1360px;
`;

const PageGridComponent = () => {
  return (
    <PGShipmentDetailsPickedUp>
      <SecondaryNav></SecondaryNav>
      <NavPrimary></NavPrimary>
          <GComponentPageTitle>
              <PageTitle>TRUCKLOAD SHIPMENT 34954115</PageTitle>
          </GComponentPageTitle>
      <GPickedUpContentGrid></GPickedUpContentGrid>
      <Footer></Footer>
    </PGShipmentDetailsPickedUp>
  )
}

export default PageGridComponent;